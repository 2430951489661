<template>
  <NuxtLayout>
    <Container>
      <div class="mx-auto max-w-screen-lg py-40">
        <h2 class="text-3xl font-semibold">
          {{ $t(`error.${error.statusCode}.title`) }}
        </h2>
        <div class="my-8 max-w-[35rem] text-balance">
          {{ $t(`error.${error.statusCode}.text`) }}
        </div>
        <AppButton size="sm" @click="clearError({ redirect: '/' + locale })">
          {{ $t('general.backToFrontPage') }}
        </AppButton>

        <AppButton
          v-if="error.statusCode === 403"
          size="sm"
          class="ml-4"
          @click="clearError({ redirect: getShopRoute('/shop/login') })"
        >
          {{ $t('account.login') }}
        </AppButton>

        
      </div>
    </Container>
  </NuxtLayout>
</template>

<script setup lang="ts">
const props = defineProps<{
  error: {
    statusCode: number
    message: string
    stack: any
  }
}>()

const config = useRuntimeConfig()
const route = useRoute()
if (props.error.statusCode === 404) {
  const hasRedirect = await $fetch<{
    to: string | null
    type: string
  }>(
    `${config.public.API_URL}/link-redirector/check?from=${encodeURIComponent(route.fullPath)}`
  )
  if (hasRedirect.to) {
    await navigateTo(hasRedirect.to, { redirectCode: +hasRedirect.type })
  }
}

console.error(props.error.statusCode, props.error.message)

const { getWishlistProducts } = useWishlist()
const { refreshCart } = useCart()
const { t, locale } = useI18n()
const { getShopRoute } = useShopRouter()

try {
  const { globalsCms, globalsShop } = await useInitGlobals()
  provide(CMS_GLOBALS, globalsCms)
  provide(SHOP_GLOBALS, globalsShop)
} catch (e) {
  console.error('Error during global initialization:', e)
}

// Shopware prefix
provide('urlPrefix', config.public.SHOP_PREFIX)

// notifications won't work without initializing them first
useNotifications()

// Use SSR-safe IDs for Headless UI
provideHeadlessUseId(() => useId())

useHead({
  title: t(`error.${props.error.statusCode}.title`),
  titleTemplate: (title) =>
    title
      ? `${import.meta.dev ? '🚧 ' : ''}${title} - JP Rosselet Cosmetics`
      : `${import.meta.dev ? '🚧 ' : ''}JP Rosselet Cosmetics`,
})

onMounted(() => {
  getWishlistProducts()
  refreshCart()
})
</script>
